@import "../../common.scss";
@import "../../dashboardCommon.scss";

// main layout css
.dashBoardLayout {
  .copyWright_dashboard {
    position: absolute;
    bottom: 10px;
    color: $copyWright-color !important;
    display: flex;
    align-items: flex-end;
    font-family: Montserrat;
    font-size: 10px;
    font-weight: 600;
    line-height: 14px;
    letter-spacing: 0px;
    text-align: left;
  }

  .selectDivision {
    .ant-select-arrow {
      margin-top: 0 !important;
    }
  }

  .upcomingVisaSelectSection {
    display: flex;
    gap: 10px;
    align-items: center;
    justify-content: space-between;

    .ant-select-arrow {
      top: 20px;
      right: -1px;
    }
  }

  .upcomingVisaExp {
    border-radius: 16px;
    box-shadow: 0px 8px 16px 0px #8f95b226;
    background-color: $paperbackgroundGreen;
    height: 100%;
    > div {
      height: 100%;
      > div {
        height: 100%;
        > div:last-child {
          margin-top: auto;
        }
      }
    }

    .flexCenter {
      cursor: pointer;
      gap: 10px;
    }

    .viewAll {
      font-size: 18px;
      color: $link_pale_blue;
    }

    .next60 {
      color: $descriptionColor;
    }

    .paperItemLine {
      h3 {
        margin: 0;
        font-size: 26px;
        font-weight: 400;
        height: 100%;
      }

      .mainValues {
        margin-bottom: 20px;
      }
    }
  }

  .dashboardPolicyCharts {
    margin-top: 40px;
    height: 100%;

    .ant-spin-nested-loading {
      height: 100%;

      > div {
        height: 100%;

        > div {
          height: 100%;

          > div {
            height: 100%;
          }
        }
      }
    }
  }

  .dashboardServiceGraph {
    margin-top: 40px;
  }

  .dashboardBarGraph {
    @include dashboardCard;

    & .ant-card-body {
      padding: 0px !important;
      height: 100%;
    }

    .servicegraphFilters {
      margin-bottom: 20px;

      .servicegraphTitle {
        font-family: $MontserratFont;
        font-size: 18px !important;
        font-weight: 400 !important;
        line-height: 22px;
        letter-spacing: 0em;
      }

      .serviceGraphFilterBtns {
        display: flex;
        align-items: center;
        justify-content: flex-end;
        gap: 10px;
      }
    }

    .barChartStyle {
      min-height: 400px;
    }

    .barChartLegends {
      margin-top: 2%;
      width: auto;
    }
  }

  .ant-menu-submenu-selected {
    .ant-menu-submenu-title {
      background: $dashBoard-btn !important;
      color: $selectedItemColor !important;
    }
  }

  .ant-menu-item {
    &ul {
      background-color: $color-white !important;
    }
  }

  .ant-menu-item-selected {
    border-radius: 12px;
    background-color: $selectedItemColor 20%;
    color: $selectedItemColor !important;
  }

  .ant-menu-submenu {
    margin-top: 16px !important;

    & .dashboardMenuIcon {
      fill: red !important;
    }

    & ul {
      background-color: $color-white !important;
    }
  }

  .mainLayoutContent {
    height: 100%;
    width: 100%;
    background-color: $backGroundColor;
    overflow-y: scroll;
  }

  .ant-select-dropdown {
    width: 300px !important;
  }

  .selectDivision {
    .ant-select-selector {
      border: none;
      background: #ffff;
      padding: 8px 18px;
      width: auto;
      min-width: 150px;
      height: auto;
      box-shadow: 1px 8px 16px 0px rgba(143, 149, 178, 0.15);
      border-radius: 6px;
    }

    .ant-select-dropdown {
      width: 300px !important;
      /* Set your desired width */
    }

    .ant-select-item-option-content {
      padding: 8px 18px;
      width: 150px;
    }

    svg {
      color: #111 !important;
      position: relative;
      left: -8px;
    }
  }

  .next60 {
    .ant-select-selector {
      background: none;
      border: none;
      color: #5a607f;
      box-shadow: none !important;
    }

    svg {
      color: #5a607f !important;
    }
  }

  .paperItemHover {
    transition: all 0.25s;

    &:hover {
      box-shadow: 1px 8px 16px 1px rgb(0 0 0 / 15%);
    }
  }
}

// dashboard css

.dashboardContent {
  .ant-btn {
    border-color: $copyWright-color;
    padding: 0;
    min-width: 36px;
    align-items: center;
    justify-content: center;
    display: inline-flex;
  }

  padding: 40px 30px;
  overflow-y: scroll;

  .dashboardTitleWrapper {
    margin-bottom: 30px;

    > div {
      display: flex;
      align-items: center;
    }

    .dashboardTitle {
      @include dashboardTitle;
      margin-bottom: 0;
    }
  }

  .divisionUnitPaper {
    text-align: center;
    justify-content: center;
    vertical-align: middle;
    box-shadow: 1px 8px 16px 0px #8f95b226;
    padding: 10px 16px;
    margin-left: auto;

    & .ant-card-body {
      width: 100% !important;

      & a {
        width: 100% !important;
        display: flex;
        align-items: center;
        gap: 10px;
        color: $metal_blue;

        & svg {
          width: 12px;
        }
      }
    }

    .divisionUnitDropdown {
      height: 64px;
      width: 337px;
      left: 0px;
      top: 18px;
      border-radius: 0px;
      text-align: center;
      @include dropDownFontStyle;
    }

    .ant-card-body {
      height: 100%;
      display: flex;
      justify-content: center;
      align-items: center;
      position: relative;
      vertical-align: middle;
      padding: 0;
    }
  }

  .mapAndCounter {
    height: 100%;

    .totalSpendsCards {
      display: flex;
      gap: 30px;
      flex-wrap: wrap;

      .totalSpends,
      .totalAuths {
        min-height: 148px;
      }

      > div {
        width: 100%;
        min-height: calc(50% - 15px);
        // max-height: calc(50% - 15px);

        > div {
          height: 100%;

          > div {
            height: 100%;
          }
        }
      }

      .totalSpends {
        .ant-card-body {
          padding: 18px !important;

          & h3 {
            margin: 0 !important;
          }

          h1 {
            margin-top: auto;
          }
        }

        border-radius: 16px;
        background-color: $paperbackgroundGreen;
      }

      .totalAuths {
        .ant-card-body {
          padding: 18px !important;

          & h3 {
            margin: 0 !important;
          }

          h1 {
            margin-top: auto;
          }
        }

        border-radius: 16px;
        background-color: $paperbackgroundBlue;
      }
    }

    .dashboardMapCards {
      height: auto;
      align-self: stretch;

      .dashboardMap {
        height: 100%;
        @include dashboardCard;

        .authByCountryHeading {
          margin: 0;
          @include dashboardTitle;
        }

        .mapTablePaper {
          margin-top: 30px;
          width: 100%;

          .ant-card-body {
            padding: 2px !important;
          }

          .ant-table-wrapper {
            width: 100%;
            max-width: 100%;
            overflow-x: auto;
          }

          .ant-table td {
            white-space: nowrap;
            text-overflow: ellipsis;
            overflow: hidden;
            max-width: 150px;
            border-bottom: none;
            text-align: left;
            padding: 9px;
            color: #202020;
          }

          .ant-table th {
            white-space: nowrap;
            text-overflow: ellipsis;
            overflow: hidden;
            max-width: 150px;
            text-align: left;
            padding: 5px;
            background-color: $color-white;
            color: $color-grey;

            &:before {
              content: "";
              display: none;
            }
          }
        }

        #svgMap {
          margin-top: 10%;
        }

        .dashboardMapFilters {
          display: flex;
          gap: 10px;
          justify-content: flex-end;
        }
      }
    }
  }

  .paperItemLine {
    display: flex;
    flex-direction: column;
    gap: 20px;

    > span {
      display: flex;
      flex-direction: column;
      gap: 10px;
    }

    svg {
      width: 40px;
      position: relative;
      top: 0;
    }

    h3 {
      font-size: 21px;
      line-height: 1.3;
      width: 100%;
    }

    h2 {
      margin-bottom: 0;
      font-size: 38px;
      width: 100%;
    }

    .mainValues {
      margin-top: auto;
    }
  }

  .dashboardPolicyCharts {
    .donutheading {
      @include donutChartHeading;
      margin-bottom: 30px;
    }

    .donutGraphitem {
      height: 100%;
      background: white;
      border: none;
      min-height: 300px;

      .ant-card-body {
        @include dashboardCard;
        padding: 30px 24px;
      }
    }

    #donutChartCanvas {
      width: 200px !important;
      height: 200px !important;
      margin: auto;
      margin-bottom: 20px;
    }
  }

  .ant-input {
    border-color: $copyWright-color;
  }
}

.favoriteSaveButton,
.applyFiltersBtn {
  margin-top: auto;
  width: calc(50% - 15px);
  color: #fff;
  background-color: $selectedItemColor !important;
  border-color: $selectedItemColor !important;
  box-shadow: 1px 1px 15px rgba(22, 175, 244, 0.23);

  &:hover span {
    color: #fff;
  }
}

.applyFiltersBtn {
  width: 100%;
  height: 100%;
  //margin-top: 40px;
}

.policyFilter {
  margin-top: 14px;
  @include checkboxItem;
  width: 100%;
  display: flex;
  font-size: 16px;
}

.ant-checkbox-checked {
  .ant-checkbox-inner {
    background-color: $inputFocus !important;
  }
}

.ant-checkbox-wrapper {
  margin-inline-start: 0 !important;
}

.borderLessTable {
  border: none;
  box-shadow: 1px 4px 7px rgba(0, 0, 0, 0.03);

  th {
    font-weight: 400 !important;
  }
}

.donutChartContainer {
  position: relative;

  h3 {
    padding-right: 50px;
  }

  .donutActions {
    position: absolute;
    display: flex;
    flex-direction: column;
    gap: 10px;
    top: 0;
    right: 0;
  }
}

.menuIconDiv .ant-row {
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
}

.menuIcon {
  position: relative;
  font-size: 22px;
  cursor: pointer;
  display: flex;
  justify-content: flex-end;
}

.leftIcon {
  display: flex;
}

.leftIcon > svg {
  width: 30px;
  height: 30px;
  margin-right: 30px;
  border: 1px solid #e8e8e8;
  border-radius: 16px;
  padding: 5px;
}

@media screen and (max-width: 768px) {
  .dashboardContent .dashboardTitleWrapper .dashboardTitle {
    font-size: 18px;
    font-weight: 500;
  }

  .dashboardContent {
    padding: 30px 16px !important;
  }

  .dashboardContent
    .mapAndCounter
    .dashboardMapCards
    .dashboardMap
    .authByCountryHeading {
    font-size: 18px;
    font-weight: 500;
  }

  .dashboardHeaderLeft {
    display: flex;
    gap: 10px;
    justify-content: space-between;
    padding-right: 10px;
  }

  .dashBoardLayout.dashBoardCommonStyle .dashboardHeaderLeft .companyName {
    font-size: 14px;
  }

  .dashboardUserBar.mobile {
    display: flex;
    gap: 10px;
  }
}

.dashboardRightInner {
  display: none;
}

@media (max-width: 576px) {
  .dashboardRightInner {
    display: none;
  }

  .leftIcon {
    display: none;
  }
}

.ant-row .ant-row-middle {
  display: flex !important;
  flex-direction: row !important;
  justify-content: space-between !important;
}

.defautlBtnPad:disabled {
  filter: grayscale(1);
  color: #fff;
}
