.userIcon {
  width: 35px;
  height: 35px;
  border-radius: 16px;
  margin-right: 10px;
  min-width: 35px;
}

.oneLineOverflow {
  line-break: anywhere;
  text-overflow: ellipsis;
  overflow: hidden;
  max-width: 100%;
  display: -webkit-box;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
}

#notificationBell {
  cursor: pointer;

  svg {
    width: 25px;
    height: 25px;
  }

  .ant-scroll-number {
    font-size: 10px !important;
    font-weight: 600 !important;
    padding: 0 !important;
  }
}

.notificationPopupX {
  width: 620px !important;
  padding: 18px 16px 10px !important;

  .ant-notification-notice-close {
    top: 6px !important;
    inset-inline-end: 8px !important;
  }
}

.notificationPopup {
  display: flex;
  gap: 20px;

  .icon {
    img {
      width: 40px;
      height: 40px;
    }
  }

  .data {
    h2 {
      margin: 0;
      line-height: 1.1;
      font-weight: 400;
      font-size: 16px;
    }

    p {
      color: #59588d;
      font-size: 13px;
      margin: 10px 0 4px;
      line-height: 1.2;
    }

    .note {
      margin: 0;
    }
  }

  .name {
    margin-left: auto;
    font-size: 13px;
    color: #3b414f;
    line-height: 1.2;
  }
}

.notificationTooltip {
  width: min(440px, 80vw);
  max-width: min(440px, 80vw);

  .ant-tooltip-inner {
    background-color: #fff;
  }

  .ant-tooltip-arrow {
    right: 4px;
  }

  .ant-tooltip-arrow:before {
    background-color: #fff;
  }

  .notificationBody {
    padding: 10px;
    padding-bottom: 16px;
    position: relative;

    .confirmClearAll {
      z-index: 5;
      position: absolute;
      top: 47px;
      left: -8px;
      background: #fff;
      padding: 18px;
      padding-top: 0;
      text-align: center;
      box-shadow: 0 15px 14px 0px #0001;
      width: calc(100% - 22px);

      h3 {
        line-height: 1.4;
        font-weight: 500;
        font-size: 20px;
      }

      .action {
        display: flex;
        gap: 20px;
        justify-content: space-between;

        button {
          height: 40px;
          width: 120px;
        }
      }
    }

    .head {
      display: flex;
      justify-content: space-between;
      gap: 20px;
      align-items: center;
      margin-bottom: 16px;

      h3 {
        margin: 0;
        color: #3b414f;
        font-weight: 400;
      }

      .headAction {
        display: flex;
        gap: 20px;
        align-items: center;

        > * {
          cursor: pointer;

          &:hover {
            opacity: 0.8;
          }
        }

        .selectNotification {
          color: #626c81;
        }

        .cancel {
          color: #d84049;
          font-weight: 500;
        }
      }
    }
  }

  .list {
    display: flex;
    flex-direction: column;
    max-height: calc(80vh - 200px);
    overflow-y: auto;
    position: relative;
    padding: 0 18px;
    margin-left: -18px;
    width: 100%;

    .item {
      display: flex;
      gap: 14px;
      padding: 6px 0;
      transition: all 0.4s ease-in-out;
      position: relative;

      &.hideNotification {
        opacity: 0;
        pointer-events: none;

        &::after {
          content: "";
          position: absolute;
          top: 0;
          left: -18px;
          width: calc(100% + 36px);
          height: 100%;
          background-color: #70b2ff44;
          z-index: 1;
        }
      }

      .icon img {
        width: 40px;
      }

      .data {
        flex: 1;
      }

      .title {
        display: flex;
        gap: 12px;
        justify-content: space-between;
      }

      h4 {
        margin: 0;
        color: #3b414f;
        font-weight: 400;
        font-size: 16px;
        min-width: 60%;
      }

      .name {
        color: #3b414f;
        font-weight: 300;
        font-size: 12px;
      }

      .description {
        color: #626c81;
        font-size: 14px;
        margin-top: 5px;
        line-height: 1.2;
      }

      .date {
        color: #59588d;
        font-size: 12px;
        margin-top: 7px;
      }
    }
  }

  .markRead {
    text-align: center;
    margin-top: 10px;

    > span {
      color: #70b2ff;
      font-weight: 500;
      cursor: pointer;
      text-decoration: underline;
    }
  }

  .notiFoot {
    display: flex;
    justify-content: space-between;
    align-items: center;

    .clearBtn {
      width: 18px;
      height: 18px;
      cursor: pointer;

      &:hover {
        opacity: 0.7;
      }
    }
  }

  .noNoti {
    display: flex;
    justify-content: center;
    flex-direction: column;
    gap: 10px;
    align-items: center;

    img {
      width: 110px;
    }

    h3 {
      margin: 0;
      color: #3b414f;
      font-weight: 600;
      font-size: 20px;
    }

    p {
      margin: 0;
    }
  }
}

@media (max-width: 600px) {
  .notificationTooltip {
    width: calc(100% - 40px);
    max-width: calc(100% - 40px);
    right: 20px !important;
  }
}

.cityHelpSticky {
  position: fixed;
  bottom: 20px;
  right: 20px;
  width: 50px;
  height: 50px;
  cursor: pointer;

  z-index: 10;

  img {
    width: 100%;
  }
}

.city-help-tooltip {
  .ant-popover-inner-content {
    color: #fff;
  }
  .ant-popover-inner {
    padding: 8px !important;
    font-size: 12px;
  }
}
